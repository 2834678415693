<template>
  <div class="cbox">

    <div style="width:100%;height: 100%">
      <div class="flexStart" style="font-size: 14px; height: 100%">
        <div style="width: 33%; height: 100%">
          <el-card class="cuscard cuscard1 box-card" style="width: 100%; height: 100%">
            <div slot="header" class="clearfix">
              <span>部门列表</span>

              <el-button size="mini" icon="el-icon-plus" @click="formAdd" type="primary"
                style="margin-left: 20px; float: right">新增</el-button>

              <el-button size="mini" @click="exportXls" type="primary"
                style="margin-left: 20px; float: right">导出xls</el-button>
            </div>

            <div class="treeContainer"
              style="padding-right:10px;width: calc( 100% - 0px ); height: calc(100%); overflow-y: auto">
              <el-tree :data="treeData" :props="firstCascaderProps" :default-expand-all="true"
                :expand-on-click-node="false" @node-click="handleNodeClick">
                <div class="custom-tree-node flexBetween" slot-scope="{ node, data }" style="width: 100%">
                  <div>
                    <span>{{ node.label }}</span>
                    <span style="font-size: 10px; color: #8c939d; margin-left: 10px">{{ node.data.fgxz_name }}</span>
                  </div>
                  <div>
                    <el-button @click="handleAdd(data)" type="text" size="small" style="color: #1289ff">
                      <i class="el-icon-circle-plus-outline"></i>
                    </el-button>
                    <el-button @click="handleModify(data)" type="text" size="small" style="color: #67c23a">
                      <i class="el-icon-edit"></i>
                    </el-button>
                    <el-popconfirm width="250" confirmButtonText="确定" cancelButtonText="取消" icon="el-icon-info"
                      iconColor="red" @confirm="handleDel(data)" placement="left" title="是否确定删除？">
                      <el-button slot="reference" type="text" size="small" style="color: #fe6c6c; margin-left: 10px">
                        <i class="el-icon-delete"></i>
                      </el-button>
                    </el-popconfirm>
                  </div>
                </div>
              </el-tree>
            </div>
          </el-card>
        </div>
        <div class="tabContainer" style="margin-left: 20px; width: calc(66% - 20px); height: 100%">
          <el-card class="cuscard box-card" style="width: 100%; height: 100%">
            <div slot="header" class="clearfix">
              <span>全部教师</span>
              <el-button v-if="deptId" size="small" icon="el-icon-guide" @click="showUser" type="primary"
                style="margin-left: 20px; float: right">关联用户</el-button>
            </div>

            <div style="height: calc( 100% - 40px ); width: 100%;">
              <Table :tableData="tableData" :page="page" @handleModify="handleModify" @handleDel="handleDel"
                @handleSelectionChange="handleSelectionChange" from="dept" style="height: 100%; width: 100%;"> </Table>
            </div>
          </el-card>
        </div>
      </div>
      <el-dialog :title="modalTitle" :visible.sync="isShowModal" :close-on-click-modal="false" :destroy-on-close="true">
        <el-form :model="formData" :rules="rules" ref="adminUserInfo" label-width="130px"
          style="max-height: 450px; overflow-y: auto; min-height: 300px">
          <el-form-item label="上级部门" prop="pid">
            <!-- <el-cascader ref="firstCascader" :options="treeData" :disabled="modalTitle == '修改'" :props="defaultProps"
              v-model="formData.pid" :show-all-levels="false" @change="handleCascaderChange" placeholder="请选择"
              filterable style="width: 50%"> </el-cascader> -->
            <el-cascader ref="firstCascader" :options="treeData" :props="defaultProps"
              v-model="formData.pid" :show-all-levels="false" @change="handleCascaderChange" placeholder="请选择"
              filterable style="width: 50%"> </el-cascader>
          </el-form-item>

          <el-form-item label="部门名称" prop="dept_name">
            <el-input v-model.trim="formData.dept_name" style="width: 50%" />
          </el-form-item>
          <el-form-item label="部门代码" prop="dept_code">
            <el-input v-model.trim="formData.dept_code" style="width: 50%" />
          </el-form-item>
          <el-form-item label="部门角色">
            <el-select v-model="formData.role_ids" multiple placeholder="请选择">
              <el-option v-for="(item, index) in deptRoleList" :key="index" :label="item.role_name"
                :value="item.id"></el-option>
            </el-select>

            <el-button size="small" icon="el-icon-plus" type="default" @click="goDeptRole">添加</el-button>
          </el-form-item>
          <el-form-item label="关联实验室">
            <el-select v-model="formData.room_ids" @change="handleSelectRoom" filterable multiple placeholder="请选择"
              style="width: 50%">
              <el-option v-for="(item, index) in rooms" :key="index" :label="item.room_name"
                :value="item.id"></el-option>
            </el-select>
          </el-form-item>

          <el-form-item label="教研室主任">
            <el-tag v-for="tag in fgxz" :key="tag.id" @close="removeUser(1,tag.id)" closable>
              {{ tag.username }}
            </el-tag>
            <el-button size="small" circle icon="el-icon-plus" style="margin-left: 20px"
              @click="handleSelectDept(1)"></el-button>
          </el-form-item>
          <el-form-item label="任课教师/管理员">
            <el-tag v-for="tag in gly" :key="tag.id" @close="removeUser(2,tag.id)" closable style="margin-left: 5px">
              {{ tag.username }}
            </el-tag>
            <el-button size="small" circle icon="el-icon-plus" style="margin-left: 20px"
              @click="handleSelectDept(2)"></el-button>
          </el-form-item>
          <el-form-item label="排序号" prop="sort">
            <el-input-number :min="0" v-model.trim="formData.sort" placeholder="请输入排序号(数字)" controls-position="right" />
          </el-form-item>
        </el-form>
        <el-row>
          <el-col :span="22" style="text-align: right">
            <el-button size="small" type="default" @click="isShowModal = false">取消</el-button>
            <el-button size="small" type="primary" @click="submitForm('adminUserInfo')">保存</el-button>
          </el-col>
        </el-row>
      </el-dialog>

      <User ref="visiable_user" :editOptions="editUser" @saveUser="saveUser" from="dept" :slctList="tableData"> </User>

      <el-dialog title="选择部门角色" :visible.sync="isShowDeptRole" :close-on-click-modal="false" :destroy-on-close="true"
        width="400px" top="60px">
        <el-tree ref="deptRoleTree" :props="{ children: 'children', label: 'role_name' }" node-key="id"
          :data="deptRoleList" :default-expand-all="true" show-checkbox> </el-tree>

        <div style="text-align: right; padding: 10px; margin-top: 10px; border-top: 1px solid #ccc">
          <el-button type="primary" icon="el-icon-s-claim" size="small" @click="saveDeptRole">保存</el-button>
        </div>
      </el-dialog>
    </div>
    <deptuser v-if="DeptUserDialog" v-on:getUser="getUser"></deptuser>
  </div>
</template>
<script>
import api from "@/api/api";
import User from "./components/User";
import Table from "./components/TeacherTable";
import deptuser from "./components/deptuser";

export default {
  name: "dept",
  components: {
    User,
    Table,
    deptuser,
  },
  data() {
    return {
      visible: false,
      isShowModal: false,
      modalTitle: "添加",
      DeptUserDialog: false,
      formData: {},
      fgxz: [],
      gly: [],
      defaultProps: {
        label: "dept_name",
        value: "id",
        children: "children",
        emitPath: false,
        checkStrictly: true,
      },
      //table
      tabMapOptions: [
        {
          label: "全部教师",
          key: "CN",
        },
      ],
      activeName: "CN",
      tableData: [],
      rules: {
        dept_name: [
          {
            required: true,
            message: "请输入部门名称",
            trigger: "blur",
          },
        ],
        dept_code: [
          {
            required: true,
            message: "请输入部门代码",
            trigger: "blur",
          },
        ],
      },
      teacherList: [],
      editUser: {},
      deptId: null,
      editDeptRole: {},
      deptRoleList: [],
      isShowDeptRole: false,

      page: {
        pageIndex: 1,
        pageSize: 10000,
        showPage: false,
      },
      multipleSelection: [],
      treeData: [],
      firstCascaderProps: {
        label: "dept_name",
        value: "id",
        children: "children",
        emitPath: false,
        multiple: true,
        checkStrictly: true,
      },
      rooms: [],
      roleType: 1,
    };
  },
  watch: {
    "formData.pid"() {
      if (this.$refs.firstCascader) {
        this.$refs.firstCascader.dropDownVisible = false; //监听值发生变化就关闭它
      }
    },
  },
  mounted() {
    this.getTree();
    this.getDeptRoles();
    this.getRooms();
  },
  methods: {
    getTree() {
      let _this = this;
      this.$http.post(api.tea_deptList).then((res) => {
        if (res.data && res.data.length > 0) {
          _this.deleteEmptyGroup(res.data).then((result) => {
            _this.treeData = result;
            this.getTeachers();
          });
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    // 处理没有children的分组
    deleteEmptyGroup(treeData) {
      return new Promise((resolve) => {
        function traversal(data) {
          data.map((item) => {
            for (let info in item) {
              if (item["children"]) {
                if (item["children"].length > 0) {
                  traversal(item["children"]);
                }
                if (item["children"].length == 0) {
                  delete item["children"];
                }
              }
            }
          });
        }

        traversal(treeData);
        resolve(treeData);
      });
    },
    getTeachers() {
      let params = {
        page: this.page.pageIndex,
        pagesize: this.page.pageSize,
      };
      if (this.deptId) {
        params.ktype = "dept_id";
        params.keyword = this.deptId;
      }

      // sys_dept_user_related_list
      this.$http.post("/api/zichan_teachers_list", params).then((res) => {
        this.tableData = res.data.data;
        this.page.total = res.data.page.count;
      });
    },
    getTechByIds(ids) {
      if (ids) {
        this.$http
          .post("/api/get_teacher_byids", {
            ids: ids,
          })
          .then((res) => {
            this.fgxz = res.data;
          });
      } else {
        this.fgxz = [];
      }
    },
    getUser(e) {
      if (e && e.length > 0) {
        if (this.roleType === 1) {
          this.fgxz = e;
          this.formData.fgxz = e[0].id;
        } else {
          if (!this.gly.filter((g) => g.id === e[0].id).length) {
            this.gly.push(e[0]);
          }
        }
      }
      this.DeptUserDialog = false;
    },
    getDeptRoles() {
      this.$http.post(api.deptRoleList).then((res) => {
        this.deptRoleList = res.data.data;
      });
    },

    handleNodeClick(data) {
      // console.log(data);
      this.deptId = data.id;
      this.deptInfo = data;
      this.tabMapOptions[0].label = `${data.dept_name}`;
      this.getTeachers();
    },
    handleSelectionChange(val) {
      let _this = this;
      _this.multipleSelection = val;
    },
    formAdd() {
      this.isShowModal = true;
      this.fgxz = [];
      this.gly = [];
      this.formData = {};
      this.formData = {
        pid: 0,
        sort: 1,
      };
      this.formData.node_type = "dept";
      this.modalTitle = "新增部门";
    },
    exportXls() {
      this.$http.post("/api/teacher_dept_xls").then((res) => {
        window.open(res.data.url, "_blank");
      });
    },
    handleAdd(row) {
      this.isShowModal = true;
      this.formData = {};
      this.formData = {
        pid: row.id,
        sort: 1,
      };
      if (row.node_type == "dept") {
        this.formData.node_type = "major";
      } else if (row.node_type == "major") {
        this.formData.node_type = "class";
      }
      this.modalTitle = "新增部门";
    },
    handleModify(row) {
      row.room_ids = row.rooms.map((r) => r.room_id);
      this.gly = JSON.parse(JSON.stringify(row.class_teachers));
      this.isShowModal = true;
      this.modalTitle = "修改";
      this.formData = { ...row };
      this.getTechByIds(row.fgxz);
    },
    handleDel(row) {
      console.log("api.tea_deptDel:" + api.tea_deptDel);
      this.$http
        .post(api.tea_deptDel, {
          id: String(row.id),
        })
        .then((res) => {
          this.$message.success("删除成功");
          this.getTree();
        });
    },
    handleCascaderChange(e) {
      console.log(1, e);
    },

    submitForm(formName) {
      let _this = this;
      _this.$refs[formName].validate((valid) => {
        if (valid) {
          let params = {
            ..._this.formData,
          };
          if (!params.pid) {
            params.pid = 0;
          }
          if (params.role_ids && params.role_ids.length > 0) {
            params.role_ids = params.role_ids.join(",");
          }
          if (params.room_ids) {
            params.room_ids = params.room_ids.join(",");
          }

          if (this.gly.length > 0) {
            params.gly_ids = this.gly.map((item) => item.id).join(",");
          }

          this.$http.post(api.tea_deptSave, params).then((res) => {
            this.$message.success("保存成功");
            this.getTree();
            this.isShowModal = false;
          });
        }
      });
    },
    showUser() {
      this.editUser = {};
      this.$refs.visiable_user.show();
      console.log(this.tableData);
    },
    saveUser(e, oldSlctList) {
      let _this = this;
      let idsO = [...oldSlctList];
      let idsN = [...e];

      let ids = [],
        json = [],
        flagArr = [];
      if (oldSlctList && oldSlctList.length > 0) {
        oldSlctList.map((o) => {
          if (e && e.length > 0) {
            let len = e.length;
            for (let i = 0; i < len; i++) {
              if (e[i].depts && e[i].depts.length > 0) {
                e[i].depts.map((b) => {
                  let obj = {};
                  if (b.deptRoles && b.deptRoles.length > 0) {
                    b.deptRoles.map((c) => {
                      obj = {
                        dept_id: Number(b.dept_id),
                        teacher_id: Number(b.teacher_id),
                        role_id: Number(c.role_id),
                        sort: 1,
                      };
                      json.push(obj);
                    });
                  } else {
                    obj = {
                      dept_id: Number(b.dept_id),
                      teacher_id: Number(b.teacher_id),
                      role_id: 0,
                      sort: 1,
                    };
                    json.push(obj);
                  }
                });
              }
              let newObj = {};
              newObj = {
                dept_id: Number(this.deptId),
                teacher_id: Number(e[i].id),
                role_id: 0,
                sort: 1,
              };
              json.push(newObj);
            }
          }
        });

        //处理取消选中的旧数据
        let arr = [...idsO];
        if (idsO && idsO.length > 0) {
          for (let i = idsO.length; i--; ) {
            if (idsN && idsN.length > 0) {
              idsN.map((n) => {
                if (idsO[i].id == n.id) {
                  // console.log(idsO[i])
                  arr.splice(i, 1);
                }
              });
            }
          }
          // console.log(360,idsO,arr)
        }

        if (arr && arr.length > 0) {
          arr.map((item) => {
            if (item.depts && item.depts.length > 0) {
              for (let qi = item.depts.length; qi--; ) {
                if (item.depts[qi].dept_id == _this.deptId) {
                  item.depts.splice(qi, 1);
                } else {
                  let flagObj = {};
                  if (item.depts[qi].deptRoles && item.depts[qi].deptRoles.length > 0) {
                    item.depts[qi].deptRoles.map((s) => {
                      // console.log(s)
                      flagObj = {
                        dept_id: Number(q.dept_id),
                        teacher_id: Number(q.teacher_id),
                        role_id: Number(s.role_id),
                        sort: 1,
                      };
                      flagArr.push(flagObj);
                    });
                  } else {
                    flagObj = {
                      dept_id: Number(q.dept_id),
                      teacher_id: Number(q.teacher_id),
                      role_id: 0,
                      sort: 1,
                    };
                    flagArr.push(flagObj);
                  }
                }
              }

              // console.log(flagArr)
            }
          });
        }
      } else {
        if (e && e.length > 0) {
          let len = e.length;
          for (let i = 0; i < len; i++) {
            // console.log(i,e[i])
            if (e[i].depts && e[i].depts.length > 0) {
              e[i].depts.map((b) => {
                console.log(b);
                let obj = {};
                if (b.deptRoles && b.deptRoles.length > 0) {
                  b.deptRoles.map((c) => {
                    console.log(c);
                    obj = {
                      dept_id: Number(b.dept_id),
                      teacher_id: Number(b.teacher_id),
                      role_id: Number(c.role_id),
                      sort: 1,
                    };
                    json.push(obj);
                  });
                } else {
                  obj = {
                    dept_id: Number(b.dept_id),
                    teacher_id: Number(b.teacher_id),
                    role_id: 0,
                    sort: 1,
                  };
                  json.push(obj);
                }
              });
            }
            let newObj = {};
            newObj = {
              dept_id: Number(this.deptId),
              teacher_id: Number(e[i].id),
              role_id: 0,
              sort: 1,
            };
            json.push(newObj);
          }
        }
      }
      let paramsArr = json.concat(flagArr);
      console.log(1234, paramsArr, _this.getUnique2(paramsArr));
      paramsArr = _this.getUnique2(paramsArr);

      this.$http
        .post("/api/sys_dept_user_related_edit", {
          json: paramsArr,
        })
        .then((res) => {
          this.$message.success("保存成功");
          this.getTree();
          this.$refs.visiable_user.handleCancel();
        });
    },

    getUnique2(arr) {
      const map = {};
      // 1、把数组元素作为对象的键存起来（这样就算有重复的元素，也会相互替换掉）
      arr.forEach((item) => (map[JSON.stringify(item)] = item));

      // 2、再把对象的值抽成一个数组返回即为不重复的集合
      return Object.keys(map).map((key) => map[key]);
    },

    showDeptRole(row) {
      this.deptId = row.id;
      this.isShowDeptRole = true;
    },
    saveDeptRole() {
      let ids = this.$refs["deptRoleTree"]
        .getCheckedNodes(false, true)
        .map((e) => {
          return e.id;
        })
        .join(",");
      console.log(ids);
      this.$http
        .post(api.deptRoleRelated, {
          dept_id: this.deptId,
          role_id: ids,
        })
        .then((res) => {
          this.$message.success("保存成功");
          this.getTree();
          this.isShowDeptRole = false;
        });
    },
    goDeptRole() {
      this.$router.push("/deptrole");
    },
    getRooms() {
      this.$http.post("/api/t_sch_sysrooms_list", { page: 1, pagesize: 1000 }).then((res) => {
        this.rooms = res.data.data;
      });
    },
    handleSelectDept(val) {
      this.DeptUserDialog = true;
      this.roleType = val;
    },
    removeUser(type,id) {
      if (type === 1) {
        this.fgxz = this.fgxz.filter((e) => e.id !== id)
        this.formData.fgxz = null
      } else {
        this.gly = this.gly.filter((e) => e.id !== id);
      }
    },
    handleSelectRoom(val) {
      if (val.length > 0) {
        this.$http.post("/api/sys_rooms_teacher", { room_ids: val.join(",") }).then((res) => {
          if (res.data && res.data.length) {
            this.gly = res.data;
          }
        });
      }
    },
  },
};
</script>
<style lang="less">
.cuscard {
  box-sizing: border-box;
  .el-card__body {
    box-sizing: border-box;
    height: calc(100% - 60px);
  }
}
.cuscard1 {
  .el-card__body {
	padding-right: 10px!important;
  }
}
</style>
<style scoped lang="less" type="text/less">
.app-container {
  .treeContainer {
    height: 100%;
    background-color: #fff;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    border: 1px solid rgba(220, 223, 230, 0.6);
    margin-right: 20px;
    padding-right: 10px;

    .treeTitle {
      height: 40px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      color: #409eff;
      padding-left: 6px;
    }
  }
}
</style>
