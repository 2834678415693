<template>
    <div>
        <el-dialog title="用户列表" :visible.sync="visible" :close-on-click-modal="false">
            <el-row class="flexStart">
                <el-select v-model="searchForm.ktype" size="small"
                           placeholder="请选择" style="width: 150px;">
                    <el-option label="用户名" value="username">用户名</el-option>
                    <el-option label="工号" value="ucode">工号</el-option>
                </el-select>
                <el-input v-model="searchForm.keyword" size="small"
                          placeholder="请输入" style="max-width: 200px;margin-right: 10px"/>
                <el-button size="small" icon="el-icon-search" @click="onSearch" plain type="primary">查询</el-button>
            </el-row>
            <el-row style="margin: 20px 0;">
                <el-table ref="fileTable"
                    :data="tableData"
                    style="width: 100%;margin-bottom: 20px;"
                    border stripe @selection-change="handleSelectionChange">
                    <el-table-column
                        type="selection"
                        width="55">
                    </el-table-column>
                    <el-table-column prop="username" label="教师姓名"></el-table-column>
                    <el-table-column prop="ucode" label="工号"></el-table-column>
                    <el-table-column prop="phone" label="手机号"></el-table-column>
                </el-table>
                <el-pagination
                    @current-change="handlePageChange"
                    :current-page="page.pageIndex"
                    :page-size="page.pageSize"
                    layout="total, prev, pager, next, jumper"
                    :total="page.total">
                </el-pagination>
            </el-row>
    
            <el-row class="flexEnd">
                <el-button size="small" type="default" @click="handleCancel">取消</el-button>
                <el-button size="small" type="primary" @click="handleOk">确认</el-button>
            </el-row>
        </el-dialog>
    </div>
</template>
<script>
	import api from '@/api/api';

	export default {
		name: 'DeptRole',
		data () {
			return {
				visible:false,
				tableData: [],
				page: {
					pageIndex: 1,
					pageSize: 10000,
					total: 0
				},
				multipleSelection:[],
				searchForm:{ktype: 'username'},
			}
		},
		props: {
			editOptions: {
				type: Object
			},
            from: {
                type: String,
                default: ''
            },
            slctList:{
                type: Array,
                default: []
            },
		},
		methods: {
			handleOk () {
                // console.log(1234,this.slctList)

                let ids = ''
				if(this.multipleSelection&&this.multipleSelection.length>0){
					ids = this.multipleSelection.map((item, index) => {
						return item.id
					}).join(',')
					this.$emit('saveUser', this.multipleSelection,this.slctList)
				} else {
					this.$message.warning('请至少选择一条数据')
				}
			},
			handleCancel () {
				// this.$emit('close')
				this.visible = false
			},
			show () {
				this.getList().then(result=>{
                    this.visible = true
                    if(this.from == 'dept'){
                        this.$nextTick(() => {
                            for (let i = 0; i < this.tableData.length; i++) {
                                for (let j = 0; j < this.slctList.length; j++) {
                                    if(this.tableData[i].id==this.slctList[j].id){
                                        this.$refs.fileTable.toggleRowSelection(this.tableData[i])
                                    }
                                }
                            }
                        })
                    }
                })
            },
			getList () {
			    return new Promise(resolve => {
                    let params = {
                        page: this.page.pageIndex,
                        pagesize: this.page.pageSize,
                        ktype: this.searchForm.ktype,
                        keyword: this.searchForm.keyword,
                    }
                    this.$http.post("/api/zichan_teachers_list", params).then(res => {
                        this.tableData = res.data.data;
                        this.page.total = res.data.page.count;
                        resolve(this.tableData)
                    })
                })
			},
			handleSelectionChange(val) {
				this.multipleSelection = val;
            },
			handlePageChange (e) {
				this.page.pageIndex = e
				this.getList()
			},
			onSearch () {
				this.tableData = [];
				this.page.pageIndex = 1;
				this.getList();
			},
		}
	}
</script>
