const api = {

	//菜单
	menuList: '/api/zichan_sys_menu_list',
	menuSave: '/api/zichan_sys_menu_edit',
	menuDel: '/api/zichan_sys_menu_delete',
	//用户角色(todo ok)
	roleList: '/api/zichan_sys_role_list',
	roleSave: '/api/zichan_sys_role_edit',
	roleDel: '/api/zichan_sys_role_delete',
	getRoleMenu: '/api/zichan_get_role_menu',
	setRoleMenu: '/api/zichan_set_role_menu',
	//教师部门(todo ok)
	tea_deptList: '/api/sys_department_tree',
	tea_deptSave: '/api/sys_department_edit',
	tea_deptDel: '/api/sys_department_delete',
	//部门角色(todo ok)
	deptRoleList: '/api/sys_dept_role_list',
	deptRoleSave: '/api/sys_dept_role_edit',
	deptRoleDel: '/api/sys_dept_role_delete',
	

}
export default api
