<template>
  <div class="flexCloumn">
    <el-table class="flex_1" :data="tableData" height="100%"  style="width:fit-content;width:100%;" border stripe
              @selection-change="handleSelectionChange">
      <el-table-column type="selection" width="55" align="center">
      </el-table-column>
      <el-table-column prop="index" width="55" label="序号" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 + ((page.pageIndex - 1) * page.pageSize) }}
        </template>
      </el-table-column>
      <el-table-column prop="username" label="头像" width="60" align="center">
        <template slot-scope="scope">
          <img :src="scope.row.avatar" v-if="scope.row.avatar"
               style="width: 32px; width: 32px; vertical-align: top;">
        </template>
      </el-table-column>
      <el-table-column prop="username" label="教师姓名" width="100" align="center">
        <template slot-scope="scope">
					<span style="color: #007AFF; cursor: pointer;" @click="unbindTeacher(scope.row)"
          >{{ scope.row.username }}</span>
          <i class="el-icon-link" title="已绑定微信" v-if="scope.row.openid"></i>
        </template>
      </el-table-column>
      <el-table-column prop="ucode" label="工号" width="100" align="center"></el-table-column>
      <el-table-column prop="phone" label="手机号" width="135" align="center"></el-table-column>
      <el-table-column prop="job_state" label="状态" width="80" align="center">
        <template slot-scope="scope">
          <span v-if="scope.row.job_state==1">在职</span>
          <span v-if="scope.row.job_state==2">离职</span>
          <span v-if="scope.row.job_state==3">调岗</span>
          <span v-if="scope.row.job_state==4">其他</span>
        </template>
      </el-table-column>
      <el-table-column prop="sort" label="排序" width="80">
        <template slot-scope="scope">
          <div @click="editrowid=scope.row.id" v-if="editrowid!=scope.row.id">{{ scope.row.sort }}</div>
          <el-input size="mini" v-else v-model="scope.row.sort" @blur="saveSort(scope.row)"></el-input>
        </template>
      </el-table-column>

      <el-table-column prop="index" :label=" from!='dept'?'部门(点击设置)':'部门'"  min-width="220" >
        <template slot-scope="scope">
          <div v-if="scope.row.depts&&scope.row.depts.length>0" @click="showOneDept(scope.row)"
               style="cursor: pointer;">
            <div v-for="(d,idx) in scope.row.depts" :key="idx">
              <el-tag size="mini"> {{ d.dept_name }} <span v-for="(dr,i) in d.deptRoles"
                                                           :key="i">-{{ dr.role_name }}</span></el-tag>
            </div>
          </div>
          <div v-else @click="showOneDept(scope.row)" style="cursor: pointer;color: #67c23a">
            未设置
          </div>
        </template>
      </el-table-column>


      <el-table-column v-if="from!='dept'" prop="index" min-width="100" label="权限角色(点击设置)">
        <template slot-scope="scope">
          <div style="cursor: pointer;" v-if="scope.row.roles&&scope.row.roles.length>0"
               @click="showOneTeacher(scope.row)">
            <div class="roleitem" v-for="(d,idx) in scope.row.roles" :key="idx">
              <el-tag size="mini"> {{ d.role_name }}</el-tag>
            </div>

          </div>
          <div v-else @click="showOneTeacher(scope.row)" style="cursor: pointer;color: #67c23a">
            未设置
          </div>
        </template>
      </el-table-column>

      <!-- <el-table-column prop="index" width="100" label="状态">
                <template slot-scope="scope">
                    <div v-if="scope.row.locked" style="color: orangered;">
                        已删除
                    </div>
                    <div v-else>正常</div>
                </template>
            </el-table-column>
-->

      <el-table-column v-if="from!='dept'" fixed="right" label="操作" width="250">
        <template slot-scope="scope">
          <el-popconfirm width="250" confirmButtonText='确定' cancelButtonText='取消' icon="el-icon-info"
                         iconColor="red" @confirm="handleDel(scope.row)" placement="left" title="是否确定删除？">
            <a slot="reference" size="small"
               style="color: red;font-size: 12px;margin-right: 10px; cursor: pointer;">删除</a>
          </el-popconfirm>
          <el-button @click="handleModify(scope.row)" type="text" size="small">修改</el-button>
          <el-button @click="resetPwd(scope.row)" type="text" size="small">重置密码</el-button>
          <el-dropdown :split-button="false" trigger="click" type="text" class="dropdown el-dropdown-link">
            <a style="font-size: 12px;margin-left: 20px;cursor: pointer">
              更多<i class="el-icon-caret-bottom el-icon--right"></i>
            </a>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click.native="releaseDept(scope.row)" style="font-size: 12px;">解除部门关联
              </el-dropdown-item>

            </el-dropdown-menu>
          </el-dropdown>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination class="cus_page"
                   @size-change="handleSizeChange"
                   @current-change="paginate"
                   :current-page.sync="page.pageIndex"
                   :page-sizes="[10, 50, 100]"
                   :page-size="page.pageSize"
                   layout="total,sizes, prev, pager, next"
                   :total="page.total">
    </el-pagination>
  </div>
</template>
<script>
export default {
  name: 'Table',
  data() {
    return {
      editrowid: 0,
    }
  },
  props: {
    page: {
      type: Object,
    },
    tableData: {
      type: Array,
      default: []
    },
    from: {
      type: String,
      default: ''
    },
    multSelect: {
      type: Boolean,
      default: true
    },
  },
  methods: {
    handleModify(row) {
      this.$emit('handleModify', row)
    },
    handleDel(row) {
      this.$emit('handleDel', row)
    },
    handleSelectionChange(val) {
      this.$emit('handleSelectionChange', val)
    },
    handlePageChange(e) {
      this.$emit('handlePageChange', e)
    },
    handleSizeChange(e) {
      this.$$parent(this,'handleSizeChange', e)
    },
    paginate(e){
      this.$$parent(this,'paginate', e)
    },
    showOneDept(e) {
      this.$emit('showOneDept', e)
    },
    showOneTeacher(e) {
      this.$emit('showOneTeacher', e)
    },
    resetPwd(e) {
      this.$emit('resetPwd', e)

    },
    releaseDept(e) {
      this.$emit('releaseDept', e)

    },
    saveSort(row) {
      this.editrowid = 0;
      this.$http.post("/api/set_teacher_sort", {
        id: row.id,
        sort: row.sort
      }).then(res => {
        this.$message.success("修改成功")
      })
    },
    unbindTeacher(row) {
      if (row.openid) {
        this.$confirm('解绑后该微信登陆将失效，是否解绑?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.$http.post("/api/teacher_unbindwx", {
            id: row.id
          }).then(res => {
            this.$message.success("解绑成功")

            this.$emit('handlePageChange', this.page.pageIndex)
          })
        }).catch(() => {

        });
      }
    }

  }
}
</script>
<style lang="less">
.cus_page {
  margin-top: 10px;

  .el-pagination__sizes {
    width: 130px;
  }
}
</style>