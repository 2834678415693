<template>
  <div v-if="dept.expand">
    <div v-if="dept.children.length > 0" v-for="(sdept,index) in dept.children" :key="index" style="padding-left: 10px;">
      <div class="sdeptname">
        <div @click="showDept(sdept)">
          <span class="sdept">↳</span><span
            :class="['sj',sdept.expand?'zk':''] ">▷</span>{{ sdept.dept_name }}
          <span class="unum"
                v-if="sdept.teachers.length>0">{{ sdept.teachers.length }}人</span>
          <span class="unum " @click.stop="ckAll(sdept)"
                v-if="sdept.teachers.length>0&&mult">全选</span>
        </div>
                <dept-item v-if="sdept.children"
                           :dept="sdept"
                           :mult="mult"
                           :prevIdx="index"
                           :temphead="temphead"
                           @ckAll="ckAll"
                           @ckOne="ckOne"
                ></dept-item>
        <div v-if="sdept.expand">
          <div v-for="(teacher,i) in sdept.teachers" :key="i"
               style="padding-left:10px ;">
            <div class="teacher">
              <img class="userhead" :src="teacher.avatar||temphead"
                   alt="">{{ teacher.username }}
              <span class="leader"
                    v-if="sdept.leader==teacher.id">部门领导</span>
              <div class="btnadd" @click.stop="ckOne(teacher)">选择
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import DeptItem from './DeptItem'

export default {
  name: 'DeptItem',
  props: ["dept", 'mult', 'prevIdx','temphead'],
  components: {DeptItem},
  data() {
    return {}
  },
  methods: {
    showDept(node) {
      node.expand = !node.expand;
    },
    ckAll(node) {
      this.$emit('ckAll', node, 1)
    },
    ckOne(row) {
      this.$emit('ckOne', row)
    },
  },
}
</script>
<style scoped>
.deptname,
.teacher,
.sdeptname {
  line-height: 28px;
  cursor: pointer;
}
.wsml{width: 100%;}
.wbig{ width: 49%;}
.sj {
  display: inline-block;
  transition: all 0.3s ease-out;
}

.sdept {
  display: inline-block;
  transform: translateY(-5px);
  padding-right: 5px;
  color: #ccc;
}

.zk {
  transform: rotateZ(90deg);
}

.teacher {
  padding-left: 10px;
  border-bottom: 1px solid #eeeeee;

}

.teacher:hover {
  background-color: #eeeeee;
}

.userhead {
  width: 20px;
  height: 20px;
  vertical-align: middle;
  border-radius: 10px;
  transform: translateY(-2px);
  margin-right: 5px;
}

.btnadd {
  float: right;
  margin-right: 10px;
  color: #007AFF;
}

.unum {
  margin-left: 10px;
  font-size: 12px;
  color: #007AFF;
}

.chduser {
  padding: 10px 20px;
  border: 1px solid #eeeeee;
  display: inline-block;
  margin: 0 10px 10px 0;
  position: relative;
}

.remove {
  position: absolute;
  width: 14px;
  height: 14px;
  background-size: cover;
  top: 0px;
  right: -5px;
  background-color: #fff;
  border-radius: 50%;
  overflow: hidden;
}

.remove img {
  width: 100%;
  height: 100%;
  vertical-align: top;
}

.leader {
  color: orangered;
  font-size: 12px;
  margin-left: 10px;
}
</style>